
import { defineComponent, onMounted, ref, watch } from "vue";
  import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "companies",
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const moduleList = ref<any[]>([
      {
        id: 1,
        title: "Ogzatech A.Ş.",
        category: "Yazılım Geliştirme",
        desc: "Şirketin kendi projeleri haricinde isteğe özel projeler de geliştirilmektedir. Mobil/Web/Windows uygulamalarının yanı sıra akademik danışman kadrosuyla yapay zeka, görüntü işleme, optimizasyon gibi ARGE projeleri de yapmaktadır.",
        modalCount: 8,
        date: new Date(),
      },
      {
        id: 2,
        title: "Yıldız Teknik Teknopark",
        category: "Üniversite - Araştırma Geliştirme",
        desc: "Yıldız Teknik Üniversitesi, ülkemizin ar-ge faaliyetlerine katkıda bulunan 21. yüzyılın girişimci üniversite modeli doğrultusunda teknoloji geliştirme bölgesi açma faaliyetlerine başlamış ve 2003 yılında Yıldız Teknopark’ı kurmuştur.",
        modalCount: 5,
        date: new Date(),
      },
      {
        id: 3,
        title: "Baykar Teknoloji",
        category: "Savunma Sanayi",
        desc: "Türkiye merkezli bir makine imalat ve savunma şirketidir. Baykar Makina ismiyle 1986 yılında Özdemir Bayraktar tarafından otomotiv sanayine yerlileştirmeye tabi hassas motor, pompa ve dişli kutusu parçalarının imalatı konusunda hizmet etme amacıyla kurulmuştur.",
        modalCount: 4,
        date: new Date(),
      },
      {
        id: 4,
        title: "Benq Lightning",
        category: "Teknoloji",
        desc: "BenQ, çocukların göz sağlığı için tasarlanmış konforlu aydınlatma çözümleri sunmaktadır. Sıfır doğrudan parlama, geniş ve eşit aydınlatma, daha geniş masalar için kompakt ışık başlığı ve otomatik parlaklık ayarı gibi özellikleriyle öne çıkmaktadır.",
        modalCount: 3,
        date: new Date(),
      },
      {
        id: 5,
        title: "Spotify",
        category: "Teknoloji",
        desc: "Spotify, 2006 yılında İsveç'te kurulan bir ses akışı ve medya hizmetleri sağlayıcısıdır. Şirket, 180'den fazla ülkede 456 milyon aylık aktif kullanıcıya ve 205 milyon ücretli aboneye sahiptir",
        modalCount: 2,
        date: new Date(),
      },
      {
        id: 6,
        title: "Malitur",
        category: "Turizm",
        desc: "Malitur Turizm Ticaret Limited Şirketi, müşteri memnuniyetini sağlamak ve en güvenilir, en uygun ve en kaliteli tur operatörü olmak için çalışmalarına devam eden bir şirkettir",
        modalCount: 5,
        date: new Date(),
      },
    ]);
    onMounted(async () => {
      setCurrentPageTitle(t("modalList.title"));
    });

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      totalItems,
      moduleList,
    };
  },
});
